import { signOut } from "firebase/auth";
import { useState } from "react";
import { auth } from "../../../firebase/firebaseConfig";

interface HeaderProps {
    onSidebarToggle: (isOpen: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ onSidebarToggle }) => {

    const [isOpen, setIsOpen] = useState(true);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleSidebar = () => {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        onSidebarToggle(newIsOpen); // Notify parent
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    return (
        <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">

                    <div className="flex items-center justify-start rtl:justify-end">
                        <button
                            onClick={toggleSidebar}
                            type="button"
                            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 block md:hidden"
                        >
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                            </svg>
                        </button>


                        <a href="/dashboard" className="flex ms-2 md:me-24">
                            <img src={require('../../../assets/logo-mahb.png')} className="h-8 me-3" alt="FlowBite Logo" />
                            {/* <span className="self-center text-xl font-semibold sm:text-md whitespace-nowrap dark:text-white">FIDS 2.0</span> */}
                        </a>

                        <button
                            onClick={toggleSidebar}
                            type="button"
                            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 hidden md:block"
                        >
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                                />
                            </svg>
                        </button>

                    </div>

                    <div className="flex items-center">
                        <div className="flex items-center ms-3">
                            {/* User Menu Button */}
                            <button
                                type="button"
                                onClick={toggleDropdown}
                                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                aria-expanded={isDropdownOpen}
                            >
                                <span className="sr-only">Open user menu</span>
                                <img
                                    className="w-8 h-8 rounded-full"
                                    src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                                    alt="user photo"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isDropdownOpen && (
                <div
                    className="absolute w-48 mt-1 bg-white divide-y divide-gray-100 rounded shadow right-4 top-full dark:bg-gray-700 dark:divide-gray-600"
                    id="dropdown-user"
                >
                    <div className="px-4 py-3" role="none">
                        <p className="text-sm text-gray-900 dark:text-white" role="none">
                            Admin FIDS
                        </p>
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                            admin.fids@flowbite.com
                        </p>
                    </div>
                    <ul className="py-1" role="none">
                        <li>
                            <a
                                href="/dashboard"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                role="menuitem"
                            >
                                Settings
                            </a>
                        </li>
                        <li>
                            <button
                                type="button"
                                onClick={handleSignOut}
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Sign Out
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    )
}

export default Header