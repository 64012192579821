import { format } from 'date-fns';

const getDateFromTimestamp = (seconds: number, nanoseconds: number) => {
    const milliseconds = seconds * 1000 + nanoseconds / 1_000_000;
    return new Date(milliseconds);
};

export const convertToUTC = (seconds: number, nanoseconds: number) => {
    const date = getDateFromTimestamp(seconds, nanoseconds);
    return date.toUTCString();
};

export const convertToLocal = (seconds: number, nanoseconds: number) => {
    const date = getDateFromTimestamp(seconds, nanoseconds);
    return format(date, "EEE, dd MMM yyyy HH:mm:ss");
};