const Dashboard = () => {
  return (
    <div className="dashboard-container p-4">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold">Total Sale</h2>
          <p className="text-gray-600 mt-2">$10,000</p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold">New Customers</h2>
          <p className="text-gray-600 mt-2">150</p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold">Pending Orders</h2>
          <p className="text-gray-600 mt-2">25</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard