// icons.ts
import { FaBeer, FaCoffee, FaHome, FaUser } from 'react-icons/fa';
import {
    MdFlight, MdDashboard, MdAirlineSeatReclineExtra, MdDisplaySettings, MdOutlinePayments, MdOutlineRoomPreferences, MdOutlineSettingsAccessibility,
    MdOutlineConnectingAirports,
    MdFlightLand,
    MdFlightTakeoff,
    MdAdd,
    MdVisibility,
    MdVisibilityOff,
    MdOutlineExpandLess,
    MdOutlineExpandMore,
    MdZoomOutMap,
    MdZoomInMap,
    MdOutlineLayers,
    MdWindow,
    MdLock,
    MdArrowOutward,
    MdLocationPin,
    MdOutlineLocationOn,
    MdFilterList,
    MdKeyboardArrowLeft,
    MdKeyboardArrowDown
} from 'react-icons/md';
import { GoDotFill } from "react-icons/go";

import { AiFillAppstore } from "react-icons/ai";

export const icons = {
    beer: FaBeer,
    coffee: FaCoffee,
    home: FaHome,
    user: FaUser,
    flight: MdFlight,
    dashboard: AiFillAppstore,
    passenger: MdAirlineSeatReclineExtra,
    display: MdDisplaySettings,
    billing: MdOutlinePayments,
    airportResources: MdOutlineRoomPreferences,
    resources: MdOutlineSettingsAccessibility,
    MdOutlineConnectingAirports: MdOutlineConnectingAirports,
    MdFlightLand: MdFlightLand,
    MdFlightTakeoff: MdFlightTakeoff,
    MdAdd : MdAdd,
    MdVisibility : MdVisibility,
    MdVisibilityOff : MdVisibilityOff,
    MdOutlineExpandLess : MdOutlineExpandLess,
    MdOutlineExpandMore : MdOutlineExpandMore,
    MdZoomOutMap,
    MdZoomInMap,
    MdOutlineLayers,
    MdWindow,
    MdLock,
    MdArrowOutward,
    MdLocationPin,
    MdOutlineLocationOn,
    MdFilterList,
    GoDotFill,
    MdKeyboardArrowLeft,
    MdKeyboardArrowDown
};