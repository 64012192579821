import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/firebaseConfig";
import { collection, addDoc } from 'firebase/firestore';
import { monitorSize, resolutions, monitorBrands } from "../../../utils/sharedConst";
import toast from 'react-hot-toast';

interface NewDeviceModalProp {
    onClose: () => void;
}

const NewDeviceModal: React.FC<NewDeviceModalProp> = ({ onClose }) => {
    const [deviceCode, setDeviceCode] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [modelNo, setModelNo] = useState('');
    const [modelId, setModelId] = useState('');
    const [brand, setBrand] = useState(monitorBrands[0]);
    const [resolution, setResolution] = useState(resolutions[0]);
    const [monitorSizeValue, setMonitorSizeValue] = useState(monitorSize[0]);
    const [orientation, setOrientation] = useState('L');
    const [isFormComplete, setIsFormComplete] = useState(false);

    const onSubmit = async () => {
        try {
            await addDoc(collection(db, "devices"), {
                deviceCode,
                ipAddress,
                serialNumber,
                modelNo,
                modelId,
                brand,
                resolution,
                monitorSize: monitorSizeValue,
                orientation,
            });
            onClose();
            toast.success('Successfully created!', { duration: 4000 });
        } catch (error) {
            toast.error('Error adding document');
        }
    };

    useEffect(() => {
        const checkFormCompletion = () => {
            // Check if all required fields are filled
            const isComplete =
                deviceCode.trim() !== "" &&
                ipAddress.trim() !== "" &&
                serialNumber.trim() !== "" &&
                modelNo.trim() !== "" &&
                modelId.trim() !== "";
            setIsFormComplete(isComplete);
        };

        checkFormCompletion();
    }, [deviceCode, ipAddress, serialNumber, modelNo, modelId]);

    return (
        <>
            <div
                id="default-modal"
                tabIndex={-1}
                aria-hidden="true"
                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
            >
                <div className="relative p-4 w-full max-w-3xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                New Device
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="default-modal"
                                onClick={onClose}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form className="w-full space-y-4">
                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <label htmlFor="input1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Device Code</label>
                                        <input
                                            type="text"
                                            id="input1"
                                            value={deviceCode}
                                            onChange={(e) => setDeviceCode(e.target.value)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Device code"
                                            required />
                                    </div>
                                    <div className="flex-1">
                                        <label htmlFor="input2" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">IP Address</label>
                                        <input
                                            type="text"
                                            id="input2"
                                            value={ipAddress}
                                            onChange={(e) => setIpAddress(e.target.value)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="IP address"
                                            required />
                                    </div>
                                    <div className="flex-1">
                                        <label htmlFor="input3" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Serial Number</label>
                                        <input
                                            type="text"
                                            id="input3"
                                            value={serialNumber}
                                            onChange={(e) => setSerialNumber(e.target.value)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Serial number"
                                            required />
                                    </div>
                                </div>

                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <label htmlFor="modelNo" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Model Number</label>
                                        <input type="text" id="modelNo" value={modelNo} onChange={(e) => setModelNo(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Model number" />
                                    </div>
                                    <div className="flex-1">
                                        <label htmlFor="modelId" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Model ID</label>
                                        <input type="text" id="modelId" value={modelId} onChange={(e) => setModelId(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Model Id" />
                                    </div>
                                    <div className="flex-1">
                                        <label htmlFor="brand" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Brand</label>
                                        <select id="brand" value={brand} onChange={(e) => setBrand(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            {monitorBrands.map((brand) => (
                                                <option key={brand} value={brand}>{brand}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <label htmlFor="resolution" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Resolution</label>
                                        <select id="resolution" value={resolution} onChange={(e) => setResolution(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            {resolutions.map((res) => (
                                                <option key={res} value={res}>{res} px</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="flex-1">
                                        <label htmlFor="monitorSize" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Monitor Size</label>
                                        <select id="monitorSize" value={monitorSizeValue} onChange={(e) => setMonitorSizeValue(Number(e.target.value))} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            {monitorSize.map((size) => (
                                                <option key={size} value={size}>{size} inches</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="flex-1">
                                        <label htmlFor="orientation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Orientation</label>
                                        <select id="orientation" value={orientation} onChange={(e) => setOrientation(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value={'L'}>Landscape</option>
                                            <option value={'P'}>Portrait</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="flex justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button
                                data-modal-hide="default-modal"
                                type="button"
                                className={`text-white ${isFormComplete
                                    ? "bg-blue-700 hover:bg-blue-800"
                                    : "bg-gray-400"
                                    } focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700`}
                                onClick={onSubmit}
                                disabled={!isFormComplete}
                            >
                                Save
                            </button>
                            <button
                                data-modal-hide="default-modal"
                                type="button"
                                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewDeviceModal;
