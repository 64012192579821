import { icons } from "../../../utils/icons"

interface PreviewDesignProps {
    onClose: () => void;
}

const PreviewTemplate: React.FC<PreviewDesignProps> = ({ onClose }) => {
    return (
        <>
            <div className="bg-zinc-200 fixed top-0 left-0 h-screen w-screen z-50">
                <button onClick={onClose} className="absolute z-50">
                    <icons.MdVisibilityOff />
                </button>

            </div>
        </>
    )
}

export default PreviewTemplate
