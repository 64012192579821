import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { Box, Button, ListItemIcon, MenuItem } from '@mui/material';
import { convertToLocal, convertToUTC } from '../../../utils/sharedFunction';
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../firebase/firebaseConfig';
import { icons } from '../../../utils/icons';

const ActiveFlightTable = () => {
    const [activeTab, setActiveTab] = useState('all');
    const [data, setData] = useState<any[]>([]);
    const [dataRes, setDataRes] = useState<any[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false)
    const [isUTC, setIsUTC] = useState(true)

    useEffect(() => {
        const res = onSnapshot(collection(db, 'activeFlights'), (snapshot) => {
            const flights = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setDataRes(flights);
            setData(flights)
            setIsFetched(true)
        }, (error) => {
            console.log('Error Fetching Data', error);
        }
        );
        return () => {
            res();
        };
    }, []);

    useEffect(() => {
        let filteredData = []
        if (activeTab === 'all') {
            setData(dataRes);
        } else if (activeTab === 'departure') {
            filteredData = dataRes.filter(item => item.arrivalDeparture === 'D');
            setData(filteredData);
        } else if (activeTab === 'arrival') {
            filteredData = dataRes.filter(item => item.arrivalDeparture === 'A');
            setData(filteredData);
        }
    }, [activeTab]);

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorFn: (row) => `${row.id}`,
                id: 'id',
                header: 'Flight No.',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <img
                            alt="avatar"
                            src={row.original.logo}
                            loading="lazy"
                            style={{ borderRadius: '50%', width: '30px', height: '30px' }}
                        />
                        <span>{renderedCellValue}</span>
                    </Box>
                ),
            },
            {
                accessorKey: 'stad',
                enableClickToCopy: true,
                header: 'STAD',
                Cell: ({ cell }) => {
                    const timestamp = cell.getValue<any>();
                    const formattedTimestamp = isUTC ? convertToUTC(timestamp.seconds, timestamp.nanoseconds) : convertToLocal(timestamp.seconds, timestamp.nanoseconds)
                    return (
                        <Box>
                            {formattedTimestamp}
                        </Box>
                    );
                },
            },
            {
                accessorKey: 'atad',
                enableClickToCopy: true,
                header: 'ATAD',
                Cell: ({ cell }) => {
                    const timestamp = cell.getValue<any>();
                    const formattedTimestamp = isUTC ? convertToUTC(timestamp.seconds, timestamp.nanoseconds) : convertToLocal(timestamp.seconds, timestamp.nanoseconds)
                    return (
                        <Box>
                            {formattedTimestamp}
                        </Box>
                    );
                }
            },
            {
                accessorKey: 'etad',
                enableClickToCopy: true,
                header: 'DISPETAD',
                Cell: ({ cell }) => {
                    const timestamp = cell.getValue<any>();
                    const formattedTimestamp = isUTC ? convertToUTC(timestamp.seconds, timestamp.nanoseconds) : convertToLocal(timestamp.seconds, timestamp.nanoseconds)
                    return (
                        <Box>
                            {formattedTimestamp}
                        </Box>
                    );
                }
            },
            {
                accessorKey: 'homeStation',
                enableClickToCopy: true,
                filterVariant: 'autocomplete',
                header: 'Station',
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue<string>() === 'KUL' ? theme.palette.warning.light : theme.palette.success.light && cell.getValue<string>() === 'KBR' ? theme.palette.secondary.light : theme.palette.primary.light,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue<number>()?.toLocaleString?.('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>
                ),
            },
            {
                accessorKey: 'arrivalDeparture',
                filterFn: 'between',
                header: 'Arr / Dep',
                size: 50,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue<string>() === 'A' ? <icons.MdFlightLand style={{ color: 'green', fontSize: '24px' }} /> : <icons.MdFlightTakeoff style={{ color: 'orange', fontSize: '24px' }} />}
                    </Box>
                ),
            },
            {
                accessorKey: 'originDestination',
                header: 'Ori / Des',
                size: 50,
            },
            {
                accessorKey: 'codeShare',
                header: 'Code Share',
            },
            {
                accessorKey: 'gate',
                header: 'Gate',
                size: 50,
            },
            {
                accessorKey: 'stand',
                header: 'Stand',
                size: 50,
            },
            {
                accessorKey: 'carousel',
                header: 'Carousel',
            },
            {
                accessorKey: 'remarks',
                header: 'Remarks',
                Cell: ({ cell, row }) => {
                    const value = cell.getValue<string>();
                    return (
                        <Button>
                            {value}
                        </Button>
                    );
                },

            },
        ],
        [isUTC],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnFilterModes: false,
        enableColumnOrdering: true,
        enableColumnPinning: true,
        enableFacetedValues: true,
        enableRowActions: true,
        enableExpandAll: false,
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select'],
                right: ['mrt-row-actions'],
            },
        },
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
            size: 'small',
            variant: 'outlined',
        },
        muiPaginationProps: {
            color: 'secondary',
            rowsPerPageOptions: [10, 20, 30],
            shape: 'rounded',
            variant: 'outlined',
        },
        renderDetailPanel: ({ row }) => (
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    left: '30px',
                    maxWidth: '1000px',
                    position: 'sticky',
                    width: '100%',
                }}
            >

                <Box sx={{ textAlign: 'center' }}>
                    {/* <CustomizedSteppers /> */}
                </Box>
            </Box>
        ),
        renderRowActionMenuItems: ({ closeMenu, row }) => [
            <MenuItem
                key={0}
                onClick={() => {
                    // View profile logic...
                    //   setOriginalData(row.original)
                    //   setIsModalOpen(true)
                    closeMenu();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    {/* <BiDetail /> */}
                </ListItemIcon>
                Edit Flight
            </MenuItem>,

            <MenuItem
                key={1}
                onClick={() => {
                    //   setDetailData(row.original)
                    //   setisOpenDetail(true)
                    closeMenu();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    {/* <FaRegEdit /> */}
                </ListItemIcon>
                View Detail
            </MenuItem>,
        ]
    });

    const LoadingSkeleton = ({ rows = 8 }) => (
        <div
            role="status"
            className="max-w p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
        >
            {[...Array(rows)].map((_, index) => (
                <div key={index} className="flex items-center justify-between pt-4">
                    <div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
            ))}
            <span className="sr-only">Loading...</span>
        </div>
    );

    return (
        <div>
            <div className="border-b border-gray-100 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('all')}
                            className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${activeTab === 'all'
                                ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                                : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                } group`}
                        >
                            <div className='w-4 h-4 me-2'>
                                <icons.MdOutlineConnectingAirports size={23} />
                            </div>
                            <p className='ml-3'>All</p>
                        </button>
                    </li>
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('departure')}
                            className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${activeTab === 'departure'
                                ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                                : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                } group`}
                        >
                            <icons.MdFlightTakeoff size={20} />
                            <p className='ml-3'>Departure</p>
                        </button>
                    </li>
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('arrival')}
                            className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${activeTab === 'arrival'
                                ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                                : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                } group`}
                        >
                            <icons.MdFlightLand size={20} />
                            <p className='ml-3'>Arrival</p>
                        </button>
                    </li>
                </ul>
            </div>
            <div className="mt-4 mx-2">
                {isFetched ? (
                    <MaterialReactTable table={table} />
                ) : (
                    <LoadingSkeleton rows={8} />
                )}
            </div>
        </div>
    );
};

export default ActiveFlightTable
