import React, { ReactNode, useState } from 'react';
import { icons } from '../../utils/icons';
import Header from '../header/view/header';
import { useNavigate } from "react-router-dom";

const sidebarItems = [
    {
        label: 'Dashboard',
        href: '/dashboard',
        icon: (<icons.dashboard size={26} />
        ),
    },
    {
        label: 'Flight Info Management',
        href: '/flight-info-management',
        icon: (<icons.flight size={26} />),
        dropdown: true,
        subItems: [
            { label: 'Active Flights', href: '/active-flight' },
            { label: 'Flights Schedules', href: '#' },
            { label: 'Flights Tracker', href: '#' },
        ],
    },
    {
        label: 'Flight Info Display',
        href: '/flight-info-display',
        icon: (<icons.display size={26} />),
        dropdown: true,
        subItems: [
            { label: 'Device Manager', href: '/device-manager' },
            { label: 'Screen Editor', href: '/template-editor' },
            { label: 'Cluster List', href: '/clusters' },
            { label: 'Display Rules Editor', href: '#' },
            { label: 'Flights Tracker', href: '#' },
        ],
    },
    {
        label: 'Resource Management',
        href: '/rms',
        icon: (<icons.resources size={26} />),
        dropdown: true,
        subItems: [
            { label: 'Gate & Stand Allocation', href: '#' },
            { label: 'Check-In Counter Allocation', href: '#' },
            { label: 'Manpower Allocation', href: '#' },
            { label: 'Capacity Planner Tools', href: '#' },
        ],
    },
    {
        label: 'Airport Resources',
        href: '/rms',
        icon: (<icons.airportResources size={26} />),
        dropdown: true,
        subItems: [
            { label: 'All', href: '#' },
            { label: 'Flight Information Display', href: '#' },
            { label: 'Gates & Stands', href: '#' },
            { label: 'Boarding Status', href: '#' },
            { label: 'Grounding Handling', href: '#' },
        ],
    },
    {
        label: 'Passenger Flow',
        href: '/passenger-flow-management',
        icon: (<icons.passenger size={26} />),
        badge: 'Pro',
    },
    {
        label: 'Billing',
        href: '/billing',
        icon: (<icons.billing size={26} />),
        badge: 'Pro',
    },
];

interface SidebarProps {
    children: ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    let navigate = useNavigate();

    const handleSidebarToggle = (isOpen: boolean) => {
        setIsOpen(isOpen);
    };

    return (
        <>
            <Header onSidebarToggle={handleSidebarToggle} />

            <aside id="logo-sidebar" className={`fixed top-0 left-0 z-40 w-1/7 h-screen pt-20 min-w-[300px] transition-transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700`} aria-label="Sidebar">
                <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
                    <ul className="space-y-2 font-medium">
                        {sidebarItems.map((item, index) => (
                            <li key={index}>
                                {!item.dropdown ? (
                                    <a href={item.href} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                        {item.icon}
                                        <span className="ms-3">{item.label}</span>
                                        {item.badge && (
                                            <span className="inline-flex items-center justify-center px-2 py-1 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300">{<icons.MdLock />}</span>
                                        )}
                                    </a>
                                ) : (
                                    <>
                                        <div className="flex space-x-0.5 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg">
                                            <button
                                                type="button"
                                                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-l-lg bg-transparent dark:text-white"
                                                onClick={() => { navigate(item.href) }}
                                            >
                                                {item.icon}
                                                {item.badge && (
                                                    <span className="inline-flex items-center justify-center px-2 py-1 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300">{<icons.MdLock />}</span>
                                                )}
                                                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">{item.label}</span>
                                            </button>

                                            <button
                                                aria-controls={`dropdown-${index}`}
                                                data-collapse-toggle={`dropdown-${index}`}
                                                className="flex items-center p-2 text-gray-900 transition duration-75 rounded-r-lg bg-transparent dark:text-white"
                                                onClick={() => {
                                                    const dropdown = document.getElementById(`dropdown-${index}`);
                                                    if (dropdown) {
                                                        dropdown.classList.toggle('hidden');
                                                    }
                                                }}
                                            >
                                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                                </svg>
                                            </button>
                                        </div>
                                        {item.badge && (
                                            <span className="inline-flex items-center justify-center px-2 py-1 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300">{<icons.MdLock />}</span>
                                        )}
                                        <ul id={`dropdown-${index}`} className="hidden py-2 space-y-2">
                                            {item.subItems.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <a href={`${item.href}${subItem.href}`} className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                                                        &#x2022; {subItem.label}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </aside>

            <div className={`p-4 mt-12 ${isOpen ? 'sm:ml-72' : 'w-full'}`}>
                {children}
            </div>
        </>
    );
};

export default Sidebar;