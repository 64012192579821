import { icons } from "../../../utils/icons"
import ClusterListTable from "../components/clusterListTable"

const ClustersManager = () => {
    return (
        <div>
            <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/3 p-4 flex">
                    <h2 className="text-4xl mr-2 text-blue-500">
                        <icons.MdOutlineLocationOn />
                    </h2>
                    <span className="text-2xl">KLIA 2</span>
                </div>

                <div className="w-full md:w-1/3 p-4 text-center">
                    <form className="max-w-md mx-auto">
                        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="search"
                                id="default-search"
                                className="shadow-lg block w-full pl-10 p-2 text-sm text-gray-900 border border-gray-300 rounded-xl bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Search Clusters..."
                                required />
                        </div>
                    </form>
                </div>
                <div className="w-full md:w-1/3 p-4 text-right">
                    <button type="button" className="text-xs font-medium text-white bg-blue-700 shadow-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm px-3 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <icons.MdAdd size={14} />
                        New Cluster
                    </button>
                    <button type="button" className="text-xs font-medium text-gray-900 bg-white shadow-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-xl text-sm px-3 py-2.5 text-center inline-flex items-center me-2 dark:bg-gray-800 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                        <icons.MdFilterList size={14} />
                        Filter
                    </button>
                </div>
            </div>
            <div className="w-full p-4 flex min-h-[calc(100vh-150px)] relative" style={{backgroundColor: '#E4EBF0'}}><ClusterListTable /></div>
        </div>
    )
}

export default ClustersManager