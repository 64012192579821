import OfflineDeviceMap from "../components/offlineDeviceMap"
import OfflineDeviceTable from "../components/offlineDeviceTable"
import OverViewAnalysis from "../components/overViewAnalysis"
import { icons } from "../../../utils/icons"
import { shortCutList } from "../../../utils/sharedConst"

const FlightInfoDisplay = () => {

    return (
        <>
            <div className="dashboard-container p-4">
                <h1 className="text-2xl font-bold mb-4">FIDS Overview</h1>
                <div className="grid grid-cols-3 gap-4 min-h-[calc(100vh-160px)] w-full">
                    {/* My First Column */}
                    <div className="flex flex-col col-span-2 gap-4 h-full">

                        <div className="justify-center rounded-xl bg-gray-50 dark:bg-gray-800">
                            <OfflineDeviceTable />
                        </div>

                        <div className="flex items-center justify-center rounded-xl bg-gray-50 dark:bg-gray-800 flex-grow">
                            <OfflineDeviceMap />
                        </div>
                    </div>

                    {/* My Second Column */}
                    <div className="flex flex-col col-span-1 gap-4 h-full">

                        {/* Overview  */}
                        <div className="flex items-center justify-center rounded-xl bg-gray-50 dark:bg-gray-800 flex-grow">
                            <OverViewAnalysis />
                        </div>

                        {/* Shortcut */}
                        <div className="flex items-center justify-center rounded-xl dark:bg-gray-800 h-1/2 w-full">
                            <div className="grid grid-cols-2 gap-4 w-full">
                                {shortCutList.map((item, index) => (
                                    <div className="w-full relative">
                                        <a
                                            href={item.href}
                                            className="block w-full p-3 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 relative" // Added relative positioning here
                                            style={{
                                                height: '80px',
                                                backgroundImage: `url(${require(`../../../assets/${item.background}`)})`, // Dynamically require the image
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            <div className="absolute inset-0 bg-white opacity-70 rounded-lg hover:opacity-30 transition-opacity duration-200" />
                                            <p className="mb-2 text-lg font-bold tracking-tight text-gray-600 absolute top-8 left-2">{item.title}</p>
                                            <small className="mb-2 tracking-tight text-gray-700 absolute top-14 left-2">{item.definition}</small>
                                            <icons.MdArrowOutward className="absolute top-2 right-2 text-gray-900" />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>


    )
}

export default FlightInfoDisplay
